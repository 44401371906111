import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { checkQuality, uploadS3 } from '../../utils/api';
import { qualityS3KeyFolder } from '../../constants';
import MD5 from 'crypto-js/md5';
import Error from './error';
import Success from './success';
import Table from './table';
import Report from './report';

export default function Form() {
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const [sfFileData, setSfFileData] = useState(null);
  const [gtnFileData, setGtnFileData] = useState(null);
  const [prevSfFileData, setPrevSfFileData] = useState(null);
  const [prevGtnFileData, setPrevGtnFileData] = useState(null);
  const [sfFileName, setSfFileName] = useState('');
  const [gtnFileName, setGtnFileName] = useState('');
  const [processStatus, setProcessStatus] = useState('');
  const [processError, setProcessError] = useState('');
  const [qualityCheckData, setQualityCheckData] = useState(null);

  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  // When the payroll changes
  useEffect(() => {
    setProcessStatus('');
    setProcessError('');
    setQualityCheckData(null);
  }, [sfFileData, gtnFileData]);

  function handleFileData(fileCat: string, event: any) {
    const fileData = event.target.files[0];
    const checksum = MD5(
      fileData.name + fileData.lastModified + fileData.size + fileData.type,
    ).toString();
    console.log(checksum);
    const fileName = fileCat + '/' + checksum + '/' + fileData.name;
    if (fileCat === 'salesforce') {
      setSfFileData(fileData);
      setSfFileName(fileName);
    } else {
      setGtnFileData(fileData);
      setGtnFileName(fileName);
    }
  }

  async function handleRunCheck(event: any) {
    event.preventDefault();
    setProcessStatus('Uploading Salesforce file');
    try {
      if (prevSfFileData != sfFileData) {
        const sfUploadRes = await uploadS3(
          qualityS3KeyFolder,
          sfFileName,
          sfFileData,
          (sfFileData as any).type,
          auth0Token,
        );
        //console.log(sfUploadRes);
        setPrevSfFileData(sfFileData);
      }
      setProcessStatus('Salesforce file Uploaded');
      setProcessStatus('Uploading GTN file');

      if (gtnFileData != prevGtnFileData) {
        const gtnUpRes = await uploadS3(
          qualityS3KeyFolder,
          gtnFileName,
          gtnFileData,
          (gtnFileData as any).type,
          auth0Token,
        );
        //console.log(gtnUpRes);
        setPrevGtnFileData(gtnFileData);
      }

      setProcessStatus('GTN file Uploaded');
      setProcessStatus('Quality Check Started');

      const qcResponse = await checkQuality(
        sfFileName,
        gtnFileName,
        auth0Token,
      );

      setQualityCheckData(qcResponse);
      setProcessStatus('Quality Check Done');
    } catch (err: any) {
      console.log(err);
      setProcessStatus('Error');
      if (err.response.status == 400) {
        setProcessError('Employee ID could not be found in the files!');
      } else if (err.response.status == 406) {
        setProcessError(
          'The input files are not valid! Only excel files are acceptable!',
        );
      } else if (err.response.status == 415) {
        setProcessError(
          'The salesforce file is not acceptable! Please check your salesforce file',
        );
      } else {
        setProcessError(err.message);
      }
    }
  }

  function arrayNotEmpty(data: any) {
    return (data as Array<any>).length > 0;
  }

  return (
    <div className="bg-gray-100">
      <form className="space-y-6 mb-10" action="#" method="POST">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Salesforce File
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Upload the file exported from Salesforce here.
              </p>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                  <div className="border sm:rounded-lg sm:p-4">
                    <div className="mt-2 flex items-center space-x-5">
                      <label className="block">
                        <span className="sr-only">Upload file</span>
                        <input
                          type="file"
                          className="block w-full text-sm text-slate-500 file:mr-4
                          file:py-2 file:px-4 file:rounded-full file:border-0
                          file:text-sm file:font-semibold file:bg-blue-200
                          file:text-teal-900 hover:file:bg-blue-300"
                          name="sfFileData"
                          onChange={e => handleFileData('salesforce', e)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-5 mb-5" />
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Gross-To-Net (GTN) File
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Upload the GTN file received from the local market here.
              </p>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                  <p className="mt-1 text-sm text-gray-900">
                    The following countries are <b>NOT</b> supported: Mexico,
                    Singapore!
                  </p>
                  <div className="border sm:rounded-lg sm:p-4">
                    <div className="mt-2 flex items-center space-x-5">
                      <label className="block">
                        <span className="sr-only">Upload file</span>
                        <input
                          type="file"
                          className="block w-full text-sm text-slate-500 file:mr-4
                          file:py-2 file:px-4 file:rounded-full file:border-0
                          file:text-sm file:font-semibold file:bg-blue-200
                          file:text-teal-900 hover:file:bg-blue-300"
                          name="gtnFileData"
                          onChange={e => handleFileData('gtn', e)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6"></div> */}

        <div className="flex justify-center">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border
            border-transparent bg-sky-800 py-2 px-4 text-sm font-medium
            text-white shadow-sm hover:bg-sky-900 focus:outline-none
            focus:ring-2 focus:ring-sky-600 focus:ring-offset-2
            disabled:opacity-50"
            onClick={handleRunCheck}
            disabled={!sfFileData || !gtnFileData}
          >
            Run Quality Check
          </button>
        </div>
        {processStatus === 'Error' && <Error message={processError} />}
        {processStatus === 'Quality Check Done' && (
          <Success message={'Quality Check Process Completed'} />
        )}
        {processStatus &&
          processStatus !== 'Error' &&
          processStatus !== 'Quality Check Done' && (
            <div className="text-center">
              <div role="status">
                <svg
                  className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="text-sm text-gray-700">{processStatus}</span>
              </div>
            </div>
          )}
      </form>

      {/* Reports */}
      <Report data={qualityCheckData} />
      {qualityCheckData && (
        <div className="accordion" id="accordionMissingId">
          {(qualityCheckData['missing_ids'] as Array<any>).length > 0 && (
            <div className="accordion-item bg-white border border-gray-400">
              <h2 className="accordion-header mb-0" id="headingMissingId">
                <button
                  className="accordion-button collapsed relative flex items-center w-full py-4 px-5
                  text-sm text-gray-800 text-left bg-indigo-50 border-0 rounded-none transition focus:outline-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMissingId"
                  aria-expanded="false"
                  aria-controls="collapseMissingid"
                >
                  Missing Worker IDs
                </button>
              </h2>
              <div
                id="collapseMissingId"
                className="accordion-collapse collapse"
                aria-labelledby="headingMissingId"
              >
                <div className="accordion-body py-4 px-5">
                  <p className="text-sm text-gray-700 mb-5">
                    The following employee/worker IDs do not exist in the
                    salesforce file:{' '}
                    {(qualityCheckData['missing_ids'] as Array<any>).join(', ')}
                  </p>
                  <div className="text-sm text-gray-700 mt-3 mb-5 inline-flex">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="exclamation-triangle"
                      className="w-4 h-4 mr-2 fill-current text-red-500"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                      ></path>
                    </svg>
                    There are{' '}
                    <strong className="text-red-500 px-1">
                      {(qualityCheckData['missing_ids'] as Array<any>).length}
                    </strong>
                    issues in this section
                  </div>
                </div>
              </div>
            </div>
          )}

          {arrayNotEmpty(qualityCheckData['invalid_names']) && (
            <div className="accordion-item bg-white border border-gray-400">
              <h2 className="accordion-header mb-0" id="headingInvalidNames">
                <button
                  className="accordion-button relative flex items-center w-full py-4 px-5
                  text-sm text-gray-800 text-left bg-indigo-50 border-0 rounded-none transition focus:outline-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInvalidNames"
                  aria-expanded="true"
                  aria-controls="collapseInvalidNames"
                >
                  GTN &quot;Employee Name&quot; should be equal to Salesforce
                  &quot;Worker Full Name&quot;
                </button>
              </h2>
              <div
                id="collapseInvalidNames"
                className="accordion-collapse collapse show"
                aria-labelledby="headingInvalidNames"
              >
                <div className="accordion-body py-4 px-5">
                  <p className="text-sm text-gray-700 mb-5">
                    The table below shows the differences between the Salesforce
                    file and the GTN file in terms of employee/worker names.{' '}
                    <br />
                    <strong>NOTE:</strong> we transform GTN names in the
                    background and then compare them with names in the
                    salesforce file, so this report only checks if after
                    transformation (e.g., removing titles, fixing the order of
                    forename-surname, etc.), the names in the GTN files would be
                    equal to those in the salesforce file. It does not alter the
                    original GTN file.
                  </p>
                  <Table data={qualityCheckData['invalid_names']} />
                </div>
              </div>
            </div>
          )}

          {arrayNotEmpty(qualityCheckData['customer_invalid']) && (
            <div className="accordion-item bg-white border border-gray-400">
              <h2 className="accordion-header mb-0" id="headingWorkerofInvalid">
                <button
                  className="accordion-button collapsed relative flex items-center w-full py-4 px-5
                  text-sm text-gray-800 text-left bg-indigo-50 border-0 rounded-none transition focus:outline-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseWorkerofInvalid"
                  aria-expanded="false"
                  aria-controls="collapseWorkerofInvalid"
                >
                  GTN &quot;Customer&quot; should be equal to Salesforce
                  &quot;Worker of: Account Name&quot; instead of &quot;Customer:
                  Account Name&quot;
                </button>
              </h2>
              <div
                id="collapseWorkerofInvalid"
                className="accordion-collapse collapse"
                aria-labelledby="headingWorkerofInvalid"
              >
                <div className="accordion-body py-4 px-5">
                  <p className="text-sm text-gray-700 mb-5">
                    The table below shows the problematic cases where GTN
                    &quot;Customer&quot; equals to Salesforce &quot;Customer:
                    Account Name&quot; instead of &quot;Worker of: Account
                    Name&quot;
                  </p>
                  <Table data={qualityCheckData['customer_invalid']} />
                </div>
              </div>
            </div>
          )}

          {arrayNotEmpty(qualityCheckData['other_invalid']) && (
            <div className="accordion-item bg-white border border-gray-400">
              <h2 className="accordion-header mb-0" id="headingCustomerInvalid">
                <button
                  className="accordion-button collapsed relative flex items-center w-full py-4 px-5
                  text-sm text-gray-800 text-left bg-indigo-50 border-0 rounded-none transition focus:outline-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseCustomerInvalid"
                  aria-expanded="false"
                  aria-controls="collapseCustomerInvalid"
                >
                  There should not be a mismatch between GTN
                  &quot;Customer&quot; and Salesforce &quot;Worker of: Account
                  Name&quot;
                </button>
              </h2>
              <div
                id="collapseCustomerInvalid"
                className="accordion-collapse collapse"
                aria-labelledby="headingCustomerInvalid"
              >
                <div className="accordion-body py-4 px-5">
                  <p className="text-sm text-gray-700 mb-5">
                    The table below shows the mismatches between GTN
                    &quot;Customer&quot; and Salesforce &quot;Worker of: Account
                    Name&quot;.
                  </p>
                  <Table data={qualityCheckData['other_invalid']} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
