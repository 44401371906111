import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './index.css';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate';
import Layout from './components/Layout';
import Quality from './Quality';
import Consolidator from './Consolidator';
import reportWebVitals from './reportWebVitals';

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLInputElement,
);

const ProtectedQuality = withAuthenticationRequired(Quality);
const ProtectedConsolidator = withAuthenticationRequired(Consolidator);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Routes>
        <Route
          path="/"
          Component={() => {
            window.location.replace('https://app.safeguardglobal.com');
            return null;
          }}
        />
        <Route
          path="/quality"
          element={
            <Layout activeSubpage="Invoice Quality">
              <ProtectedQuality />
            </Layout>
          }
        />

        <Route
          path="/consolidator"
          element={
            <Layout activeSubpage="GTN Consolidator App">
              <ProtectedConsolidator />
            </Layout>
          }
        />
      </Routes>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
