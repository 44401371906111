import React, { useState, useEffect } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { consolidateDynamic, downloadS3, uploadS3 } from '../../utils/api';
import { consolidatorS3KeyFolder } from '../../constants';
import MD5 from 'crypto-js/md5';
import Success from './success';
import Error from './error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { DocumentIcon } from '@heroicons/react/20/solid'


const FileDropzone: React.FC = () => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently } = useAuth0();


  const [processStatus, setProcessStatus] = useState('');
  const [processError, setProcessError] = useState('');
  const [filesSelected, setFilesSelected] = useState(false);

  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  const onDrop = (acceptedFiles: FileWithPath[]) => {
    setFiles([...files, ...acceptedFiles]);

    if (acceptedFiles.length > 0){
      setFilesSelected(true);
      setProcessStatus('');
    } else {
      setProcessStatus('Error');
      setProcessError('Only files with extension .xls or .xlsx can be dropped');
    }
  };

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    if (newFiles.length === 0) {
      setFilesSelected(false);
    }
    setFiles(newFiles);
  };

  const handleRemoveAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setFiles([]);
    setFilesSelected(false);
    setProcessStatus('');
  };

  async function handleRunCheck(event: any) {
    event.preventDefault();
    const arrayFileNames : string[] = [];

    try {
      setProcessStatus('Uploading Files...');

      let index = 1;
      for (const file of files) {
        const encodedName = encodeURIComponent(file.name);

        const checksum = MD5(
          encodedName + file.lastModified + file.size + file.type,
        ).toString();
        const fileName = checksum + '/' + encodedName;
        arrayFileNames.push(fileName);

        setProcessStatus('Uploading File ' + (index) + ' of ' + files.length + '...');

        const fileUploadRes = await uploadS3(
            consolidatorS3KeyFolder,
            fileName,
            file,
            (file as any).type,
            auth0Token,
        );
        index++;
      };


      setProcessStatus('Consolidation Started');

      const consolidateResponse = await consolidateDynamic(
        arrayFileNames,
        auth0Token,
      );

      const responseJson = await consolidateResponse.json();

      if (consolidateResponse.status === 200) {
        const url = await downloadS3(responseJson, auth0Token);
        const parts = responseJson.split('/');
        const filename = parts[parts.length - 1];

        fetch(url).then(response => {
          response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
          });
        });

        setProcessStatus('Consolidation Done');
      } else {
        console.log(responseJson);
        setProcessStatus('Error');
        setProcessError(responseJson);
      }
    } catch (err: any) {
      console.error(err);
      setProcessStatus('Error');
      setProcessError(err);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: {'application/vnd.ms-excel': ['.xls', '.xlsx']},
    useFsAccessApi: false,
    onDrop: onDrop,
  });

  return (
    <div>
      <div {...getRootProps()} className="border-dashed border-2 p-20 bg-white text-center
        cursor-pointer block mb-5 ml-5 mr-10 rounded-lg shadow-lg" >
        <input {...getInputProps()}/>
        {!filesSelected && (
          <div>
            <FontAwesomeIcon icon={faCloudArrowUp} size="7x" color="darkgray" />
            {isDragActive ? (
              <p className="text-md font-medium leading-6 text-gray-900">
                Drag & Drop the files HERE ...
              </p>
            ) : (
              <p className="text-md font-medium leading-6 text-gray-900 pb-50">
                Drag & Drop
                <br />
                or
                <br />
                Click Here to Select
              </p>
            )}
          </div>
        )}
        {filesSelected && (
          <div>
            <p className="text-md font-medium leading-6 text-gray-900">
              Click Anywhere to Add More Files
            </p>
            <br />
          </div>
        )}
        <div>
          {files.map((file, index) => (
            <div key={index} className="rounded-md bg-blue-200 p-4 py-2 px-4 mt-2 mb-2" >
            <div className="flex">
              <div className="flex-shrink-0  pt-1">
                <DocumentIcon className="h-5 w-5 text-black" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-black pt-1">{file.name}</p>
                <button className="rounded bg-red-900 px-2 py-1 text-sm font-medium text-white shadow-sm
                  hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                  focus-visible:outline-indigo-600"
                onClick={(event) => handleRemove(event, index)}>Remove</button>
              </div>
            </div>
          </div>
          ))}
          {filesSelected && (
            <button className="rounded bg-red-900 px-2 py-1 text-sm font-medium text-white shadow-sm
              hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
              focus-visible:outline-indigo-600 float-right mt-2 mr-1"
            onClick={(event) => handleRemoveAll(event)}>Remove All</button>

          )}
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          className="inline-flex justify-center rounded-md border
          border-transparent bg-sky-800 py-2 px-4 text-sm font-medium
          text-white shadow-sm hover:bg-sky-900 focus:outline-none
          focus:ring-2 focus:ring-sky-600 focus:ring-offset-2
          disabled:opacity-50"
          disabled={files.length < 2}
          onClick={handleRunCheck}
          style={{ cursor: files.length < 2 ? 'not-allowed' : 'pointer' }}
        >
          Consolidate Files
        </button>
      </div>
      <div className="h-10" style={{ marginTop: '10px' }}>
        {processStatus === 'Error' && <Error message={processError} />}
        {processStatus === 'Consolidation Done' && (
          <Success message={'File Downloaded in Default Download Folder'} />
        )}
        {processStatus &&
          processStatus !== 'Error' &&
          processStatus !== 'Consolidation Done' && (
            <div className="text-center">
              <div role="status">
                <svg
                  className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="text-sm text-gray-700">{processStatus}</span>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};


export default FileDropzone;
