import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FileDropzone from './components/consolidator/form';
import './App.css';
import { checkPQAuth0UserRole } from './utils/auth0';

export function Consolidator() {
  document.title = 'GTN Consolidator App';
  const { user } = useAuth0();

  //TODO Disabled until we figure out what users will have this role
  //checkPQAuth0UserRole(user);

  return (
    <>
      <div className="bg-gray-100 shadow rounded-lg">
        <div className="">
          <header>
            <div className="py-6 px-4 sm:px-6">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                GTN Consolidator App
              </h1>
              <p className="mt-2 max-w-8xl text-sm text-gray-700">
                Welcome to the GTN Consolidator App, a robust web service
                designed to automate and simplify the consolidation of different
                payroll runs. This tool efficiently merges pairs of Excel files
                by summing up the values associated with common attributes and
                appending non-common attributes at the end.
              </p>
            </div>
          </header>

          <main className="px-4 py-5 sm:p-6">
            <FileDropzone />
          </main>

          <div className="py-6 px-4 sm:px-6">
            <p className="mt-2 max-w-8xl text-sm text-gray-700">
              * The primary reference for consolidation is the EmployeeNumber,
              and the tool assumes that, excluding FirstName and LastName, all
              other columns are numeric.
            </p>
            <p className="mt-2 max-w-8xl text-sm text-gray-700">
              <strong>Disclaimer:</strong> Please ensure your files adhere to
              this format for optimal results.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Consolidator;
