import { ENV } from '../constants';
//import axios from 'axios';
import { fetchWithAuth0Token } from './auth0';

const env = ENV ? ENV : 'tst';
let apiRoot;
if (env != 'prd') {
  apiRoot = `https://api.pq.data-${env}.safeguardglobal.com`;
} else {
  apiRoot = 'https://api.pq.data.safeguardglobal.com';
}

const s3presignedApiUrl = `${apiRoot}/s3presigned`;
const qualityApiUrl = `${apiRoot}/inference`;
const consolidatorApiUrl = `${apiRoot}/consolidator`;

export async function uploadS3(
  s3KeyFolder: string,
  fileName: string,
  data: any,
  contentType: string,
  auth0Token: string,
  opts?: any,
): Promise<any> {
  try {
    const finalUrl =
      s3presignedApiUrl +
      '?operation=upload' +
      '&contentType=' +
      contentType +
      '&s3KeyFolder=' +
      s3KeyFolder +
      '&fileName=' +
      fileName;

    const response = await fetchWithAuth0Token(finalUrl, auth0Token, opts);
    const responseJson = await response.json();

    const result = await fetch(responseJson.presignedURL, {
      method: 'PUT',
      body: data,
    });

    return result;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function downloadS3(
  fileName: string,
  auth0Token: string,
  opts?: any,
): Promise<any> {
  try {
    const finalUrl = `${s3presignedApiUrl}?operation=download&fileName=${fileName}`;

    const response = await fetchWithAuth0Token(finalUrl, auth0Token, opts);
    const responseJson = await response.json();

    return responseJson.presignedURL;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function checkQuality(
  sfFileName: string,
  gtnFileName: string,
  auth0Token: string,
  opts?: any,
): Promise<any> {
  try {
    const finalUrl = `${qualityApiUrl}?gtn_file=${gtnFileName}&salesforce_file=${sfFileName}`;
    const response = await fetchWithAuth0Token(finalUrl, auth0Token, opts);
    const responseJson = await response.json();

    return responseJson;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function consolidate(
  file1Name: string,
  file2Name: string,
  auth0Token: string,
  opts?: any,
): Promise<Response> {
  try {
    const finalUrl = `${consolidatorApiUrl}?file1=${file1Name}&file2=${file2Name}`;
    return await fetchWithAuth0Token(finalUrl, auth0Token, opts);
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function consolidateDynamic(
  arrayFileNames: string[],
  auth0Token: string,
  opts?: any,
): Promise<Response> {
  try {
    const tmp = arrayFileNames.map((fileName) => `files=${encodeURIComponent(fileName)}`).join('&');
    const finalUrl = `${consolidatorApiUrl}?${tmp}`;
    return await fetchWithAuth0Token(finalUrl, auth0Token, opts);
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}
